import React from "react"
import StartupCharacteristics from '../components/profile-startup';
import { css } from '@emotion/react';
import { color } from '../components/defaults/styles/theme';
import { TitleHeader, wrapper } from '../components/defaults/styles/elements';
import { People } from "../components/defaults/assets/svg/mobilenav"
import Video from "../components/defaults/assets/svg/video.svg"
import ProfileUsers from '../components/main/profile-users';
import RearviewInline, { ifRearview } from '../components/main/rearviewInline';
import ProfileYoutube from '../components/main/profile-youtube';
import Play from "../components/defaults/assets/svg/play.svg"
import Flags from '../data/raw/flags';
import publishFilter from '../components/defaults/filters/publish';
import Calendar from './defaults/assets/svg/calendar.svg';
import AgendaEl from './main/agenda-el';
import moment from "moment"
import NavigationData from "../data/navigation_data"
import CaseStudy from './main/casestudy-el';
import Seo from './SEO/seo';

const StartupProfile = ({data, recordId}) => {

    const seo = {
      title: data.startup,
    }


    let agenda = data.agenda && data.agenda.length > 0 ? (
      data.agenda.filter(
        ({ data}) =>
          data.agenda_publish === true
      )
    ) : []

    agenda.sort((a, b) => ( a.data.agenda_start_time > b.data.agenda_start_time ? 1 : -1 ))

    let agendaDestination = ''
    NavigationData.main.forEach((e) => {
      if (e.name === "Agenda") {
        agendaDestination = e.url
        return null
      }
    })

    return (
      <>
        <Seo frontmatter={seo} />
        <StartupCharacteristics data={data} id={recordId} />
        {data.pilot_id && data.pilot_id.length > 0 ? (
          <ProfileYoutube
            icon={<Play />}
            title="Pilot Video"
            videoId={data.pilot_id}
            speakers={data.pilot_speaker}
          />
        ) : (
          ""
        )}
        <section
          css={css`
            background: ${color.main_light};
            padding-top: 5em;
            padding-bottom: 5em;
          `}
        >
          {Flags.features.casestudy === true && publishFilter(data.casestudy, "cs_publish") && publishFilter(data.casestudy, "cs_publish").length > 0 ? (
            <div css={wrapper}>
              {publishFilter(data.casestudy, "cs_publish").map(({ data }, index) => (
                <CaseStudy data={data} key={index} css={css`
                  margin-bottom: 2em;
                  :last-child {
                    margin-bottom: 5em;
                  }
                `} />
              ))}
            </div>
          ) : ''}



          {(Flags.features.people === true || Flags.features.people === "profile_only") && data.users && data.users !== undefined && data.users.length !== 0 && publishFilter(data.users, "include") && publishFilter(data.users, "include").length > 0 ? (
            <div css={css`
              margin-bottom: 5em;
            `}>
              <TitleHeader
                title={`Employees of ` + data.startup}
                icon={<People />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <ProfileUsers
                data={publishFilter(data.users, "include")}
                css={css`
                  padding-top: 0;
                  padding-bottom: 0;
                `}
              />
            </div>
          ) : (
            ""
          )}

          {Flags.features.rearview === true && data.rearview &&
          data.rearview !== undefined &&
          data.rearview.length > 0 && publishFilter(data.rearview, "publish") && publishFilter(data.rearview, "publish").length > 0 &&
          ifRearview(data.rearview) ? (
            <div css={[wrapper, css`
              margin-bottom: 3em;
            `]}>
              <TitleHeader
                title={`Rearview clips by ` + data.startup}
                inline
                icon={<Video />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <RearviewInline data={publishFilter(data.rearview, "publish")} />
            </div>
          ) : (
            ""
          )}


          {Flags.features.agenda === true && publishFilter(agenda, "agenda_publish") && publishFilter(agenda, "agenda_publish").length > 0 ? (
            <div css={[wrapper, css`
              margin-bottom: 5em;
            `]}>
              <TitleHeader
                title={`Session with (and by) ` + data.startup}
                subtitle={`<p>A sneak peak of the agenda. Find out more on <a href='${agendaDestination}'>the comprehensive overview</a>. </p>`}
                inline
                transparent
                icon={<Calendar />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              {publishFilter(agenda, "agenda_publish").map((el, index) => (
                <AgendaEl
                  key={index}
                  recordId={el.recordId}
                  title={el.data.agenda_title}
                  date={el.data.agenda_start_time}
                  start={moment(el.data.agenda_start_time).format("HH:mm")}
                  start_utc={moment(el.data.agenda_start_time)
                    .utc()
                    .format("h:mm A")}
                  duration={new Date(el.data.agenda_duration * 1000)
                    .toISOString()
                    .substr(12, 4)}
                  tags={el.data.agenda_tags}
                  speakers={el.data.agenda_speaker}
                  hosts={el.data.agenda_host}
                  teaser={el.data.agenda_teaser}
                  description={
                    el.data.agenda_description
                      ? el.data.agenda_description.childMarkdownRemark.excerpt
                      : ""
                  }
                  partners={el.data.agenda_partners}
                  startups={el.data.agenda_startups}
                  password_protected={el.data.password_protected}
                  css={css`
                    :not(:last-of-type) {
                      margin-bottom: 1em;
                    }
                  `}
                />
              ))}
            </div>
          ) : ''}


        </section>
      </>
    )
}

export default StartupProfile;